//Use the same font-family name of entire project, to use this font when font-weight is "bold"
//see https://www.456bereastreet.com/archive/201012/font-face_tip_define_font-weight_and_font-style_to_keep_your_css_simple/
@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/ErasDemiITC.otf') format('opentype');
  font-weight: bold;
	font-style: normal;
}

@import '~@angular/material/theming';
$custom-typography: mat-typography-config($font-family: 'Open Sans, sans-serif', $headline: mat-typography-level(32px, 48px, 700), $body-1: mat-typography-level(16px, 24px, 500));
@include angular-material-typography($custom-typography);

* {
  font-family: 'Open Sans', sans-serif;
}
