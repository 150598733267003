// abstract
@import "abstract/functions";
@import "abstract/mixins";
@import "abstract/variables";

// base
@import "base/base";
@import "base/animations";
@import "base/typography";
@import "base/utilities";

// components
@import "components/loader";
@import "components/badge";
@import "components/collapsible";
@import "components/progress-bar";

// layouts
@import "layouts/grid";
@import "layouts/layout";

// theme
@import "themes/theme";

// vendor
@import "vendor/material";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.main-container {
  position: absolute;
  top: 64px;
  left: 0px;
  right: 0;
  bottom: 0;
}

.push-right {
  #sidebar {
    left: 0 !important;
  }
}

.no-padding {
  header {
    width: 100%;
    height: 10vh;
    padding: 10px 15px;
    margin: 0;
  }

  .mat-dialog-container {
    padding: 0 !important;
    width: 800px !important;
  }

  .mat-dialog-actions {
    padding: 10px 15px;
    height: 10vh;
    max-height: 10vh;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0 !important;
    /* display: block; */
    align-items: center;
    flex-wrap: unset;
  }

  .mat-dialog-content {
    margin: 0;
    width: 100%;
  }
}

ins {
  color: black;
  background: #bbffbb;
}

del {
  color: black;
  background: #ffbbbb;
}

.table-container {
  overflow-x: auto;
}
.green-icon {
  color: green;
}

.sticky-header-in-dialog .table-container {
  max-height: 500px;
}
