@use '@angular/material' as mat;
@import 'theme';

.mat-expansion-panel-header {
  color: mat.get-color-from-palette($odexa-theme-primary, 800);
  background-color: mat.get-color-from-palette($odexa-theme-accent, 100);
  border-bottom: solid 2px mat.get-color-from-palette($odexa-theme-primary, 800);
}


.mat-expanded .mat-expansion-panel-content {
  padding-top: 15px;
}
