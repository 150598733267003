body {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.icon-download {
  cursor: pointer;
}
// .mat-chip-list-wrapper {
//    max-width: 500px !important;
// }

.state-cell {
  min-width: 210px !important;
}
.mat-dialog-container {
  min-width: 100% !important;
}

.disable.mat-form-field-appearance-legacy.mat-form-field-disabled
  .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.54) !important;
}

.disable .mat-input-element:disabled {
  color: black !important;
}

.disable .mat-select-value {
  color: black !important;
}
.disable .mat-select-arrow {
  color: rgba(0, 0, 0, 0) !important;
}

.disable .mat-form-field-infix {
  cursor: unset !important;
}

.mat-form-field-suffix {
  display: flex;
  .mat-icon {
    font-size: 100% !important;
  }
}

.button-bar button {
  margin: 0 2px;
}

td.text-right,th.mat-header-cell.amount {
  text-align: right;
}

th.amount .mat-sort-header-container,
th.text-right .mat-sort-header-container {
  justify-content: flex-end;
}

.hint {
  color: rgba(0, 0, 0, 0.54);
  font-size: small;
}

.no-wrap {
  white-space: nowrap;
}
