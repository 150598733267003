.badge {
  background: grey;
  padding: 5px 10px;
  color: white;
  border-radius: 15px;
  margin: 0 2px;
  text-wrap: nowrap;
  line-height: 30px;

  &--info {
    background: blue;
  }

  &--warning {
    background: orange;
  }

  &--success {
    background: green;
  }

  &--error {
    background: darkred;
  }

  &--outline_error {
    border: 2px solid darkred;
    background: transparent;
    color:darkred
  }
}
