/*
* From http://mcg.mbitson.com/
*/
$odexa-primary: (
    50 : #feefe1,
    100 : #fcd7b4,
    200 : #fabd82,
    300 : #f7a24f,
    400 : #f68e2a,
    500 : #f47a04,
    600 : #f37203,
    700 : #f16703,
    800 : #ef5d02,
    900 : #ec4a01,
    A100 : #ffffff,
    A200 : #ffe7e0,
    A400 : #ffc1ad,
    A700 : #ffae93,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$odexa-accent: (   50 : #e8e9ea,
100 : #c5c8cb,
200 : #9ea4a9,
300 : #778086,
400 : #5a646c,
500 : #3d4952,
600 : #37424b,
700 : #2f3941,
800 : #273138,
900 : #1a2128,
A100 : #6eb7ff,
A200 : #3b9dff,
A400 : #0884ff,
A700 : #0077ee,
contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
));

$odexa-warn: (
  50 : #f7e0e0,
  100 : #eab3b3,
  200 : #dd8080,
  300 : #cf4d4d,
  400 : #c42626,
  500 : #ba0000,
  600 : #b30000,
  700 : #ab0000,
  800 : #a30000,
  900 : #940000,
  A100 : #ffbfbf,
  A200 : #ff8c8c,
  A400 : #ff5959,
  A700 : #ff4040,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #ffffff,
  )
  );
