.mat-progress-bar {
  height: 10px !important;
  border-radius: 5px;

  &.full {
    .mat-progress-bar-fill::after {
      background-color: rgb(171, 0, 0);
    }
  }

}
