// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
.mat-form-field,
.mat-checkbox {
  padding: 0 0.5rem;
}

snack-bar-container.error {
  background: red;
  color: white;
}

mat-form-field.icon-only {
  width: 75px !important;
}

.mat-tooltip {
  font-size: 14px;
}
.mat-menu-item,
.mat-option {
  transition: all 0.3s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    background-color: #f6fafd !important;
  }
}
.mat-option.mat-active {
  background-color: #f6fafd !important;
}

.mat-cell, .mat-header-cell, .mat-footer-cell {
  padding: 0 5px !important;
}

.tooltip_multiline {
  white-space: pre-line;
}
